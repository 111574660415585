<template>
  <div :class="['word-row', { about_row: about }]">
    <div class="word-box word-text">{{ value.text1 }}</div>
    <div
      :class="['word-box', { 'word-text': about }]"
      v-html="value.text2"
    ></div>
    <div class="word-box" v-html="value.text3"></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    about: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.word-row {
  display: flex;
  justify-content: space-between;
  padding: 28px 0;
  &.about_row {
    padding-bottom: 0;
  }
}

.word-box {
  width: 370px;
  color: #5f5d5d;
  font-size: 14px;
  line-height: 1.8;

  &.word-text {
    font-size: 24px;
    line-height: 1.1;
    font-weight: bold;
  }
  /deep/ {
    p {
      margin: 0 0 10px;
    }
  }
}
</style>
