var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-row"},[_c('div',{staticClass:"clearfix"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[
        'list-item',
        { z_item: item.z },
        { case_item: item.case },
        { 'list-small': _vm.small } ]},[_c('div',{staticClass:"list-header"},[(item.case)?[_c('div',{staticClass:"case-img"})]:_vm._e(),[_c('list-banner',{attrs:{"swiperSlides":item.imgs,"link":_vm.link,"link_id":item.id,"small":_vm.small},on:{"change":function($event){return _vm.bannerChange(index)}}})]],2),_c('div',{staticClass:"list-content"},[(_vm.link && item.id)?[_c('router-link',{staticClass:"list-title",attrs:{"to":{
              name: _vm.link,
              params: { id: item.id },
            }},domProps:{"innerHTML":_vm._s(item.title)}})]:[_c('div',{staticClass:"list-title",domProps:{"innerHTML":_vm._s(item.title)}})],_c('div',{class:['list-info', { long_info: item.long }],domProps:{"innerHTML":_vm._s(item.info)}})],2)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }