<template>

  <div class="design-about white-bg">
    <design-header :urlCur="routeCur" />
    <design-loading v-if="loading" />
    <div :class="loading ? 'loading-cover' : ''">
      <design-banner :swiperSlides="swiperSlides" />
      <div class="design-wrapper">
        <design-breadcrumb :value="[{ name: '关于我们2222' }]" />
        <div>
          <design-word :value="word1" />
          <design-list :list="list1" />
        </div>
        <!-- <div>
          <design-word
            :value="{
              text2: '资质荣誉',
            }"
            about
          />
          <design-word :value="word2" />
          <design-list :list="list2" />
        </div> -->
        <div>
          <design-word
            :value="{
              text2: '联系我们',
            }"
            about
          />
          <design-word :value="word3" />
          <design-list :list="list3" />
        </div>
      </div>
      <!-- <div class="design_img"></div> -->
      <design-footer />
    </div>
  </div>

</template>

<script>
import designHeader from "@/components/designHeader";
import designFooter from "@/components/designFooter";
import designBanner from "@/components/designBanner";
import designBreadcrumb from "@/components/designBreadcrumb";
import designLoading from "@/components/designLoading";
import designWord from "@/components/designWord";
import designList from "@/components/designList";

import { getBanner } from "@/api/common";

export default {
  components: {
    designHeader,
    designFooter,
    designBanner,
    designBreadcrumb,
    designLoading,
    designWord,
    designList,
  },
  data() {
    return {
      word1: {
        text1: "商装宝",
        text2: `<p><a href = 'http://rp.91szb.com:10444/WebReport/ReportServer?reportlet=ResourcePart%2Ffinance%2Fsmallorder_financeList.cpt&op=write&__cutpage__=v' target='_blank'>小额订单</a></p>`,
        text3: ``,
      },
      list1: [
        {
          imgs: [
            { url: require("@/assets/design_about/list1_1.jpg") },
            { url: require("@/assets/design_about/list1_2.jpg") },
          ],
          title: "【使命】",
          info: "让商装服务变得简单高效",
          z: false,
        },
        {
          imgs: [
            { url: require("@/assets/design_about/list2_1.jpg") },
            { url: require("@/assets/design_about/list2_2.jpg") },
          ],
          title: "【愿景】",
          info: "成为商装行业首选服务商",
          z: false,
        },
        {
          imgs: [
            { url: require("@/assets/design_about/list3_1.jpg") },
            { url: require("@/assets/design_about/list3_2.jpg") },
          ],
          title: "【定位】",
          info: "一站式O2O商装服务",
          z: false,
        },
        {
          imgs: [
            { url: require("@/assets/design_about/list4_1.jpg") },
            { url: require("@/assets/design_about/list4_2.jpg") },
          ],
          title: "【核心精神】",
          info: "敢想、敢为、诚信、专业",
          z: false,
        },
        {
          imgs: [
            { url: require("@/assets/design_about/list5_1.jpg") },
            { url: require("@/assets/design_about/list5_2.jpg") },
          ],
          title: "【发展理念】",
          info: "开放、互联、共生、共赢",
          z: false,
        },
        {
          imgs: [
            { url: require("@/assets/design_about/list6_1.jpg") },
            { url: require("@/assets/design_about/list6_2.jpg") },
          ],
          title: "【核心价值观】",
          info: "责任立业、创新超越",
          z: false,
        },
      ],
      // word2: {
      //   text1: "",
      //   text2: `营业执照、开户许可证<br/>
      //           工程设计资质证书、<br/>
      //           安全生产许可证<br/>
      //           知识产权证书明细<br/>
      //           建筑业企业资质证书`,
      //   text3: `Business license, account opening license<br/>
      //           Engineering Design Qualification Certificate,<br/>
      //           Safety production license<br/>
      //           Details of intellectual property certificates<br/>
      //           Construction enterprise qualification Certificate`,
      // },
      // list2: [
      //   {
      //     imgs: [{ url: require("@/assets/design_about/p1.png") }],
      //     title: "营业执照、开户许可证",
      //     info: "",
      //   },
      //   {
      //     imgs: [{ url: require("@/assets/design_about/p2.png") }],
      //     title: "工程设计资质证书、安全生产许可证",
      //     info: "",
      //   },
      //   {
      //     imgs: [{ url: require("@/assets/design_about/p3.png") }],
      //     title: "知识产权证书明细",
      //     info: "",
      //   },
      //   {
      //     imgs: [{ url: require("@/assets/design_about/p4.png") }],
      //     title: "建筑业企业资质证书",
      //     info: "",
      //   },
      // ],
      word3: {
        text1: "",
        text2: `<p><b>商装宝网络有限公司</b>
                </p><p>电话: 028-86293888<br/>
                地址: 四川省成都市温江区金马街道太极大道670号</p>`,
        text3: `<p>Shang Zhuang Bao Network Co., LTD
                </p><p>Telephone: 028-86293888<br/>
                Address: No. 670 Taiji Avenue, Jinma Street, Wenjiang District, Chengdu, Sichuan</p>`,
      },
      list3: [
        {
          imgs: [{ url: require("@/assets/design_about/lx1.jpg") }],
          title: "",
          info: "",
        },
        {
          imgs: [{ url: require("@/assets/design_about/lx2.jpg") }],
          title: "",
          info: "",
        },
      ],
      loading: false,
      swiperSlides: [],
      routeCur: "about",
    };
  },
  watch: {},
  mounted() {
    this.initBanner();
  },
  methods: {
    initBanner() {
      getBanner(8)
        .then((res) => {
          if (res.code == 200) {
            this.swiperSlides = res.data;
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.design-about /deep/ {
  .list-title,
  .list-info,
  .word-box {
    color: #474443;
  }
}
.design_img {
  height: 513px;
  background-image: url("~@/assets/design_about/map.jpg");
}
</style>
