<template>
  <div class="design-banner">
    <swiper
      v-if="swiperSlides.length > 0"
      :options="swiperOption"
      :class="[{ 'swiper-one': swiperSlides.length == 1 }]"
    >
      <swiper-slide
        v-for="(slide, index) in swiperSlides"
        :key="index"
        class="swiper-lazy"
        :data-background="slide.bannerAvatar"
        :style="getStyle(slide.frame)"
        :data-link="slide.frame"
      ></swiper-slide>
      <div
        class="swiper-pagination design-pagination"
        slot="pagination"
        v-if="swiperSlides.length > 1"
      ></div>
    </swiper>
    <template v-if="swiperSlides.length > 1">
      <div
        class="swiper-button-next design-button-next swiper-button-white"
      ></div>
      <div
        class="swiper-button-prev design-button-prev swiper-button-white"
      ></div>
    </template>
  </div>
</template>

<script>
import { validateURL } from "@/util/util";
export default {
  components: {},
  props: {
    swiperSlides: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    swiperOption() {
      const _that = this;
      let navigation = {};
      if (this.swiperSlides.length > 1) {
        navigation = {
          nextEl: ".design-button-next",
          prevEl: ".design-button-prev",
        };
      }
      return {
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loop: this.swiperSlides.length > 1,
        // effect: "fade",
        lazy: {
          loadPrevNext: true,
        },
        pagination: {
          el: ".design-pagination",
          clickable: true,
        },
        navigation: navigation,
        on: {
          click: function (e) {
            _that.handleLink(e.target.dataset.link);
          },
        },
      };
    },
  },
  mounted() {},
  methods: {
    getStyle(link) {
      if (link && this.validateURL(link)) {
        return "cursor: pointer;";
      } else {
        return "";
      }
    },
    handleLink(link) {
      if (link && this.validateURL(link)) {
        window.open(link, "_blank");
      }
    },
    validateURL(val) {
      return validateURL(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.design-banner {
  min-width: $minWidth;
  height: 15.8105vw;
  min-height: 227px;
  max-height: 307px;
  position: relative;
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  /deep/ {
    .swiper-one .swiper-wrapper {
      transform: none !important;
    }
    .swiper-pagination {
      bottom: 40px;
    }
    .swiper-pagination-bullet {
      box-sizing: border-box;
      width: 12px;
      height: 12px;
      border: 1px solid #fff;
      background: transparent;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #fff;
    }
  }
  .swiper-button-white {
    user-select: none;
    outline: none;
  }
}
</style>
