<template>
  <div class="list-row">
    <div class="clearfix">
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="[
          'list-item',
          { z_item: item.z },
          { case_item: item.case },
          { 'list-small': small },
        ]"
      >
        <div class="list-header">
          <template v-if="item.case">
            <div class="case-img"></div>
          </template>
          <template>
            <list-banner
              :swiperSlides="item.imgs"
              :link="link"
              :link_id="item.id"
              :small="small"
              @change="bannerChange(index)"
            />
          </template>
        </div>
        <div class="list-content">
          <template v-if="link && item.id">
            <router-link
              :to="{
                name: link,
                params: { id: item.id },
              }"
              class="list-title"
              v-html="item.title"
            ></router-link>
          </template>
          <template v-else>
            <div class="list-title" v-html="item.title"></div>
          </template>
          <div
            :class="['list-info', { long_info: item.long }]"
            v-html="item.info"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listBanner from "./listBanner";

export default {
  components: {
    listBanner,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    link: {
      type: String,
      default: () => {
        return "";
      },
    },
    small: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    i_fi: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    bannerChange(i_se) {
      this.$emit("change", { i_fi: this.i_fi, i_se });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/list/design_list.scss";
</style>
