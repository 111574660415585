<template>
  <div class="design-loading">
    <div class="el-loading-spinner">
      <svg viewBox="25 25 50 50" class="circular">
        <circle cx="50" cy="50" r="20" fill="none" class="path" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "designLoading",
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.design-loading {
  .el-loading-spinner {
    z-index: 2000;
  }
}
</style>
