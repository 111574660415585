<template>
  <div class="design-breadcrumb">
    <span>您的位置：</span>
    <router-link :to="{ name: 'home' }">首页</router-link>
    <template v-for="(item, index) in value">
      <span :key="index">&gt;</span>
      <router-link v-if="item.link" :key="index" :to="{ name: item.link }">{{
        item.name
      }}</router-link>
      <span v-else :key="index" class="last">{{ item.name }}</span>
    </template>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.design-breadcrumb {
  color: #8b8b8c;
  padding: 20px 0;
  font-size: 14px;
  line-height: 19px;
  span,
  a {
    display: inline-block;
    vertical-align: top;
  }
  a {
    color: #8b8b8c;
    &:hover {
      color: $myColor;
    }
  }
  .last {
    color: #5f5d5d;
  }
}
</style>
