<template>
  <div class="list-banner">
    <swiper
      :options="swiperOption"
      :class="[{ 'swiper-one': swiperSlides.length == 1 }]"
    >
      <swiper-slide
        v-for="(slide, index) in swiperSlides"
        :key="index"
        :class="['swiper-lazy', { 'swiper-link': (link && link_id) || small }]"
        :data-background="slide.url"
        :data-index="index + 1"
      ></swiper-slide>
      <div
        class="swiper-pagination list-pagination"
        slot="pagination"
        v-show="swiperSlides.length > 1"
      ></div>
    </swiper>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    swiperSlides: {
      type: Array,
      default: () => {
        return [];
      },
    },
    link: {
      type: String,
      default: () => {
        return "";
      },
    },
    link_id: {
      type: String,
      default: () => {
        return "";
      },
    },
    small: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    swiperOption() {
      const _that = this;
      let autoplay = {};
      let loop = false;
      if (_that.swiperSlides.length > 1) {
        autoplay = {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        };
        loop = true;
      }
      return {
        autoplay,
        loop,
        lazy: {
          loadPrevNext: true,
        },
        pagination: {
          el: ".list-pagination",
          clickable: true,
        },
        on: {
          click: function (e) {
            if (e.target.dataset.index) {
              _that.handleLink();
            }
          },
        },
      };
    },
  },
  mounted() {},
  methods: {
    handleLink() {
      if (this.link && this.link_id) {
        this.$router.push({
          name: this.link,
          params: { id: this.link_id },
        });
      }
      this.$emit("change");
    },
  },
};
</script>

<style lang="scss" scoped>
.list-banner {
  height: 100%;
  position: relative;
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  /deep/ {
    .swiper-one .swiper-wrapper {
      transform: none !important;
    }
    .swiper-link {
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 0.6;
      }
    }
    .swiper-pagination {
      bottom: 30px;
    }
    .swiper-pagination-bullet {
      box-sizing: border-box;
      width: 10px;
      height: 10px;
      border: 1px solid #fff;
      background: transparent;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #fff;
    }
  }
}
</style>
